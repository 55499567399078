import React, { useEffect } from "react";
import "./Ad.scss";
import { config } from "../../Config";

const adClient = "ca-pub-6762903252690906";
// For testing
const adClientTest = "ca-pub-0123456789101112";

export const Ad = ({ adSlot }) => {
  useEffect(() => {
    ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push({});
  }, []);

  return (
    <ins
      data-adtest={config.debug ? "on" : "off"}
      className={"adsbygoogle " + (config.debug ? "debug-ad" : "")}
      style={{ display: "block", height: "100%", width: "100%" }}
      data-ad-client={config.debug ? adClientTest : adClient}
      data-ad-slot={adSlot}
    ></ins>
  );
};
